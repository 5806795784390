import React from "react";
import DateTimePicker from "react-widgets/lib/DateTimePicker";
import momentLocalizer from 'react-widgets-moment';
import "react-widgets/dist/css/react-widgets.css";

import Moment from 'moment'
Moment.locale('en')
momentLocalizer()

const VmoDatePicker = (props) => {
  return <DateTimePicker {...props} />;
};
export default VmoDatePicker;
