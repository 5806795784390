import React, { forwardRef } from "react";
import FormInput from "semantic-ui-react/dist/es/collections/Form/FormInput";

const VmoFormInput = forwardRef((props, ref) => {
  return (
    <FormInput {...props} ref={ref}>
      {props.children}
    </FormInput>
  );
});
export default VmoFormInput;
