import React, { useState, useEffect } from "react";
import { Input, Label, Button, Icon } from "semantic-ui-react";

function VmoChipInput(props) {
  const [error, setError] = useState(null);
  const [valueOfInput, setValueOfInput] = useState("");
  const [chipList, setToChipList] = useState("");

  const {
    hideInputField,
    className,
    hideRemoveButton,
    skipValidation,
    placeholder,
    onChange,
    defaultValue,
    labelProps,
    inputProps
  } = props;

  const array = { text: <div>Hello</div> };

  const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

  const handleChange = (evt) => {
    setValueOfInput(evt.target.value);
    setError(null);
  };

  const handleDelete = (index, setArray, array) => {
    let toMail = array.filter((i) => i !== index);

    setArray(toMail);
    onChange(toMail);
  };

  const onFocusOut = (evt, setArray, setValue, valueCurrent, array, setErr) => {
    if (valueCurrent !== "") {
      evt.preventDefault();

      let value = valueCurrent.trim();

      if (!skipValidation) {
        if (value && isValid(value, setErr, array)) {
          setArray([...array, value]);
          onChange([...array, value]);
          setValue("");
        }
      } else {
        setArray([...array, value]);
        onChange([...array, value]);
        setValue("");
      }
    }
  };

  const handleKeyDown = (
    evt,
    setArray,
    setValue,
    valueCurrent,
    array,
    setErr
  ) => {
    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();

      let value = valueCurrent.trim();

      if (!skipValidation) {
        if (value && isValid(value, setErr, array)) {
          setArray([...array, value]);
          onChange([...array, value]);
          setValue("");
        }
      } else {
        if (value && normalValidate(value, array)) {
          setArray([...array, value]);
          onChange([...array, value]);
          setValue("");
        }
      }
    }
  };

  function validateEmail(email) {
    const emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRex.test(email)) {
      return true;
    } else {
      return false;
    }
  }

  const normalValidate = (value, array) => {
    let errorMsg = null;
    if (array.includes(value)) {
      errorMsg = "Already Exists!";
    }
    if (errorMsg) {
      setError(errorMsg);
      return false;
    }
    return true;
  };

  function isValid(email, setErr, array) {
    let errorOfEmail = null;

    if (isInList(email, array)) {
      errorOfEmail = `${email} has already been added.`;
    }

    if (!validateEmail(email)) {
      errorOfEmail = `${email} is not a valid`;
    }

    if (errorOfEmail) {
      setErr(errorOfEmail);
      return false;
    }

    return true;
  }

  function isInList(email, array) {
    return array.includes(email);
  }

  const handlePaste = (evt, array, setArray) => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    if (emails) {
      let toBeAdded = emails.filter((email) => !isInList(email, array));

      setArray([...array, ...toBeAdded]);
      onChange([...array, ...toBeAdded]);
    }
  };

  const removeButton = (list) => {
    if (!hideRemoveButton) {
      return (
        <Icon
          name="delete"
          onClick={() => handleDelete(list, setToChipList, chipList)}
        />
      );
    } else return null;
  };

  useEffect(() => {
    if (defaultValue) {
      setToChipList(defaultValue);
    }
  }, [defaultValue]);

  return (
    <React.Fragment>
      <div className={className}>
        {(chipList || []).map((list) => (
          <Label
            key={list}
            color={list.color || null}
            {...labelProps}
          >
            {typeof list === "object" ? list.text : list}
            {removeButton(list)}
          </Label>
        ))}

        {hideInputField ? null : (
          <Input
            className={"input " + (error && " has-error")}
            key="chipInput"
            value={valueOfInput}
            placeholder={placeholder ? placeholder : "Write here..."}
            onKeyDown={(e) =>
              handleKeyDown(
                e,
                setToChipList,
                setValueOfInput,
                valueOfInput,
                chipList,
                setError
              )
            }
            onBlur={(e) =>
              onFocusOut(
                e,
                setToChipList,
                setValueOfInput,
                valueOfInput,
                chipList,
                setError
              )
            }
            onChange={handleChange}
            onPaste={(e) => handlePaste(e, chipList, setToChipList)}
            {...inputProps}
          />
        )}

        {error && <p className="error">{error}</p>}
      </div>
    </React.Fragment>
  );
}

export default VmoChipInput;
