import React from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./toast.css";


const VmoToastContainer = (props) => {
  return <ToastContainer {...props} />;
};

export default VmoToastContainer
