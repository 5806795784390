import React from "react";
import Dropdown from "semantic-ui-react/dist/es/modules/Dropdown/Dropdown";

const VmoDropdown = (props) => {
  return (
    <Dropdown
      {...props}
    />
  );
};
export default VmoDropdown;
