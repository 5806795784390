import React, { useState, useEffect } from "react";
import { Input, Button, Segment, Icon } from "semantic-ui-react";
import "../../styles.css"


const VmoAttachment = (props) => {
  const {
    content,
    onChange,
    maxSize,
    maxFiles,
    fileList,
    maxHeight,
    maxWidth,
    buttonProps={},
  } = props;

  const [files, setFiles] = useState(null);

  const defaultOnChange = (e) => {
    setFiles(e.target.files || []);
  };

  const checkFileProblem = (file, index) => {
    return new Promise((resolve, reject) => {
      let imageTypes = [
        `image/png`,
        `image/jpg`,
        `image/jpeg`,
        `image/svg`,
        `image/gif`,
      ];

      if (imageTypes.some((el) => el === file.type)) {
        let imageObject = new window.Image();
        imageObject.src = window.URL.createObjectURL(file);
        imageObject.onload = () => {
          if (maxHeight && imageObject.height > maxHeight) {
            alert(`File height allowed ${maxHeight}`);
            resolve();
            return;
          }
          if (maxWidth && imageObject.width > maxWidth) {
            alert(`File Width allowed ${maxWidth}`);
            resolve();
            return;
          }
          resolve(index);
        };
        return;
      }
      if (maxSize && file.size / 1000 > maxSize) {
        alert(`File is too big! Max File Size :${maxSize}kb`);
        resolve();
        return;
      }
      if (maxFiles && files.length > maxFiles) {
        alert("Too many Files! Max no of files :" + { maxFiles });
        resolve();
        return;
      } else {
        resolve(index);
        return;
      }
    });
  };

  useEffect(() => {
    if (files) {
      const arrFiles = Array.from(files);
      Promise.all(arrFiles.map(checkFileProblem))
        .then((indexes) => indexes.filter((el) => el !== undefined))
        .then((indexes) => {
          const passedFiles = indexes.map((index) => files[index]);
          const passedArrFiles = Array.from(passedFiles);
          if (onChange) {
            onChange(passedFiles);
          }
          if (fileList) {
            fileList(passedArrFiles.map((file, index) => ({ file, index })));
          }
        })
        .catch();
    }
  }, [files]);

  return (
    <Button icon className={"attach"} {...buttonProps}>
      {content || <Icon name="paperclip" />}
      <input
        type="file"
        {...props}
        onChange={(e) => {
          defaultOnChange(e);
        }}
      >
        {props.children}
      </input>
    </Button>
  );
};
export default VmoAttachment;
