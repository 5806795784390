import SunEditor from "suneditor-react";
import React from "react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const VmoEditor = (props) => {
  return (
    <SunEditor
      {...props}
      setOptions={{
        height: 400,
        // buttonList: buttonList.formatting,
        paragraphStyles: [
          "spaced",
          {
            name: "Custom",
            class: "__se__customClass",
          },
        ],
        font: [
          "Roboto",
          "Arial",
          "Times New Roman",
          "Comic Sans MS",
          "Courier New",
          "Impact",
          "Georgia",
          "tahoma",
        ],
        buttonList: [
          [
            "undo",
            "redo",
            "font",
            "fontSize",
            "formatBlock",
            "paragraphStyle",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            "textStyle",
            "removeFormat",
            "indent",
            "align",
            "horizontalRule",
            "list",
            "lineHeight",
            "table",
            "link",
            "image",
            "video",
            "fullScreen",
            "showBlocks",
            "codeView",
            "print",
          ],
        ],
      }}
    />
  );
};

export default VmoEditor;
