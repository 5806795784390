import React from "react";
import Popup from "semantic-ui-react/dist/es/modules/Popup/Popup";

const VmoTooltip = (props) => {
  return (
    <Popup inverted {...props}>
      {props.children}
    </Popup>
  );
};
export default VmoTooltip;
